<template>
    <div>
        <b-row>
            <b-col sm="3" xs="12">
                <h1>Organisationer</h1>
            </b-col>
            <b-col sm="9" xs="12" class="text-right" style="margin: auto">
                <b-button :to="{ name: 'admin-manage-customer-list' }" class="mr-2">
                    <font-awesome-icon icon="address-book" />    Kunder
                </b-button>
                <b-button :to="{ name: 'admin-manage-user-list' }" class="mr-2">
                    <font-awesome-icon icon="user" />  Användare
                </b-button>
                <b-button variant="primary" @click="add"> Lägg till </b-button>
            </b-col>
            <b-col md="7" sm="12" class="my-1">
                <b-form-group label-for="filter-input"
                              label-align-sm="right"
                              class="mb-0">
                    <b-input-group>
                        <b-form-input id="filter-input"
                                      v-model="filter"
                                      type="search"
                                      placeholder="Filtrera...." />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Rensa
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <b-col sm="12" md="5" class="my-1">
                <b-form-group label="Per sida"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-align-sm="right"
                              class="mb-0">
                    <PaginationPageSizeSelect id="per-page-select" v-model="perPage" />
                </b-form-group>
            </b-col>

            <b-col v-show="totalRows > perPage" cols="12" class="my-1 text-center">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="center"
                              class="my-0" />
            </b-col>
        </b-row>

        <!--TABLE-->
        <b-table :items="organizations"
                 :fields="fields"
                 :current-page="currentPage"
                 :per-page="perPage"
                 :filter="filter"
                 :filter-included-fields="['name', 'displayCreated']"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :sort-direction="sortDirection"
                 :busy="isBusy"
                 stacked="sm"
                 show-empty
                 small
                 @filtered="onFiltered"
                 @context-changed="onContextChanged">
            <template #cell(name)="row">
                <div class="overflow-hidden">                    
                        {{ row.item.name }}                    
                </div>
            </template>
            <template #cell(isActive)="row">
                <div class="overflow-hidden">
                    {{ row.item.displayIsActive }}
                </div>
            </template>
            <template #cell(actions)="row">
                <!-- Open sidebar. -->
                <font-awesome-icon class="d-none d-sm-inline text-secondary"
                                   icon="pen-square"
                                   size="lg"
                                   role="button"
                                   title="Redigera kund"
                                   @click="edit(row.item)" />

                <b-button class="text-center d-block d-sm-none"
                          block
                          @click="edit(row.item)">
                    Mer
                </b-button>
                <!-- ./ Open sidebar. -->
                <!-- Select organization. -->
                <TableToggleItem @onToggled="selectOrganization"
                                 :isDisabled="isSubmitting"
                                 :selectedId="organizationId"
                                 :itemId="row.item.id"
                                 :selectText="'Vald organisation'"
                                 :selectedText="'Välj organisation'" />
                <!-- ./ Select organization. -->
            </template>
            <template #cell(selectOrganization)="row">
            </template>
            <template #empty="">
                <h4 class="text-center">Inga resultat</h4>
            </template>
            <template #emptyfiltered="">
                <div class="text-center">
                    <h4>Inga resultat</h4>
                    <h6>
                        <a href="#" @click="filter = ''">
                            Klicka här för att rensa aktiv filtrering
                        </a>
                    </h6>
                </div>
            </template>
            <template #table-busy>
                <div class="text-center mt-4">
                    <font-awesome-icon class="align-middle"
                                       icon="spinner"
                                       size="2x"
                                       spin />
                </div>
            </template>
        </b-table>

        <!--SIDEBAR-->
        <GenericSidebar
          :visible.sync="sidebar.visible"
          :is-edit-mode.sync="sidebar.isEditMode"
          :fields="[
            { key: 'name', label: 'Namn' },
            { key: 'displayIsActive', label: 'Aktiv' },
            { key: 'displayCreated', label: 'Skapad' }
          ]"
          :item="sidebar.selected"
          @update:updateData="getData"
        >
          <template v-slot:header>
            <span v-if="sidebar.selected">
              {{ sidebar.selected.name || 'Skapa organisation' }}
            </span>
          </template>
          <template v-slot:edit>
            <b-button
              v-if="!sidebar.isEditMode"
              variant="secondary"
              class="mb-2"
              block
              @click="sidebar.isEditMode = true"
            >
              Ändra
            </b-button>
            <SaveOrganization v-if="sidebar.isEditMode" :id="sidebar.selected.id" />
          </template>
        </GenericSidebar>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { get } from '@/helpers/api';
    import SaveOrganization from '@/views/admin/manage/organization/SaveOrganization';
    import GenericSidebar from '@/components/GenericSidebar';
    import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';
    import TableToggleItem from '@/components/TableToggleItem';

    export default {
        name: 'ListOrganizations',
        components: {
            GenericSidebar,
            SaveOrganization,
            PaginationPageSizeSelect,
            TableToggleItem
        },
        data() {
            return {
                isSubmitting: false,
                sidebar: {
                    selected: null,
                    visible: false,
                    isEditMode: false
                },
                organizations: [],
                fields: [
                    { key: 'name', label: 'Namn', sortable: true, sortDirection: 'desc' },
                    {
                        key: 'isActive',
                        label: 'Aktiv',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'displayCreated',
                        label: 'Skapad',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    { key: 'actions', label: '', tdClass: 'table-list--actions--wrapper' },
                    { key: 'selectOrganization', label: '', tdClass: 'table-list--actions--wrapper' },
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                isBusy: true
            };
        },
        computed: {
            ...mapState({
                user: (state) => state.user.user
            }),
            sortOptions() {
                // Create an options list from our fields.
                return this.fields
                    .filter((f) => f.sortable)
                    .map((f) => {
                        return { text: f.label, value: f.key };
                    });
            },
            organizationId() {
                if (!this.user)
                    return null;

                return this.user.organizationId;
            }
        },
        async mounted() {
            // Fetch data.
            this.getData();
        },
        methods: {
            ...mapActions('user', ['setUserOrganization']),
            async selectOrganization(organizationId) {
                this.isSubmitting = true;

                // Post.
                this.setUserOrganization(organizationId)
                    .then(x => {
                        this.getData();
                        this.isSubmitting = false;
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                        this.isSubmitting = false;
                    });
            },
            onContextChanged(ctx) {
                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            add() {
                this.sidebar = {
                    selected: {
                        id: 0
                    },
                    visible: true,
                    isEditMode: true
                };
            },
            edit(item) {
                this.sidebar.selected = item;
                this.sidebar.visible = true;
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
            },
            async getData() {
                await get('Organization', 'GetAll')
                    .then((x) => {
                        this.organizations = x.data;

                        // Set the initial number of items.
                        this.totalRows = this.organizations.length;

                        this.isBusy = false;
                    })
                    .catch((x) => {
                        // error handling.
                    });
            }
        }
    };
</script>
